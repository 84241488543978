import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/wallet.svg";
import { StaticImage } from "gatsby-plugin-image";
import ImageBlock from "components/services/ImageBlock";
import MonetizationGrid from "components/services/MonetizationGrid";

export default () => (
  <>
    <Meta url="/services/monetization/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="Monetization"
      black="Services"
      text="AppInChina provides a convenient one-stop solution for monetizing your app or game quickly, with expert guidance on in-app payments and in-app advertising."
    />
    <ImageBlock
      title="Simple payments solution"
      text={
        <>
          <p>
            Your current in-app payments solutions won't work in China.
            Credit/debit cards are not common, and neither are services like
            PayPal, Square, Google Pay, or Apple Pay.
          </p>
          <p>
            Enter AppInChina Pay: Your seamless solution for accepting and
            managing WeChat Pay and Alipay in a single integration. Prefer
            ad-supported revenues instead? We do that too.
          </p>
        </>
      }
      img={
        <StaticImage
          src="../../images/photos/alipay.jpg"
          alt="Alipay"
          placeholder="blurred"
          layout="fixed"
          width={800}
          height={650}
        />
      }
    />
    <MonetizationGrid />
    <CTA />
    <Footer />
  </>
);
