import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Card from "components/shared/Card";
import ads from "images/svg_icons/ad-block.svg";
import payment from "images/svg_icons/payment.svg";

export default () => (
  <div className={classes.container}>
    <Layout>
      <h3>Our Monetization Solutions</h3>
      <div className={classes.cards}>
        <Card
          title="In-App Payments"
          href="/payments/"
          align="verticalreverse"
          content={
            <p>
              Accept WeChat Pay and Alipay and manage them in English on your
              client backend, with API support. We also pay all Chinese taxes on
              your behalf.
            </p>
          }
          img={payment}
        />
        <Card
          title="In-App Advertising"
          href="/services/monetization/ad-monetization/"
          align="verticalreverse"
          content={
            <p>
              We help you install the best ad networks for China so you can
              generate revenue from ads targeted specifically to Chinese
              consumers.
            </p>
          }
          img={ads}
        />
      </div>
    </Layout>
  </div>
);
